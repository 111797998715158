

/* Copyright (C) Teelblox S de RL de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * América Mendoza  <amendoza@nodeport.co>,
 * Darién Miranda <dmiranda@nodeport.co>,
 * Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------

.t-row{
  .title{
    width: 400px;
    font-weight: 600;
  }
  .amount{
    min-width: 150px;
  }
}
.stripe-data{
  .element{
    display: flex;
    .label{
      font-weight: 600;
    }
  }
  .separator{
    height: 75%;
    width: 1px;
    background-color: #E3E8EE;
    margin: auto 20px;
  }

}
