.image-preview{
  width: 100%;
  display: flex;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  .label{
    display: none;
    align-self: center;
  }
}
.image-preview:hover{
  .label{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    font-size: larger;
    color:white;
    background-color: rgba(1, 1, 1,0.3)
  }
}
.strikethrough{
  text-decoration: line-through;
  color:gray;
}
.discounted-price{
}