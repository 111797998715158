/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//-----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------IMPORTS-------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------

@import "../themeColors";

.sidebar{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  background-color: $light;
  padding: 0.5rem 0;
  min-height: 1vh;
  .active{
    text-decoration: underline !important;
  }
  overflow-y:auto;
}


@media (max-width: 576px){
  .sidebar{
    width: auto;
    span{
      display: none;
    }
  }
}
