/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------
@import "../../themeColors";

.image-selector{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .image-selected{
    width: 100%;
    display: flex;
    flex: 1;
    background-color: $medium;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    .label{
      display: none;
      align-self: center;
    }
  }
  .image-selected:hover{
    .label{
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      font-size: larger;
      color:white;
      background-color: rgba(1, 1, 1,0.3);
    }
  }
  .image-carousel{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1rem;
    overflow-x:auto;

  }
  .image-carousel-thumbnail{
    width: 120px;
    height: 120px;
    min-width: 120px;
    margin-right: 0.5rem;
    cursor: pointer;
    background-color: $light-rgb;
    border-radius: 2px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
  .image-carousel-thumbnail:last-child{
    margin-right: 0;
  }
  .is-selected{
    border:4px solid $secondary;
  }
}

