/* Copyright (C) Teelblox S de RL de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * América Mendoza  <amendoza@nodeport.co>,
 * Darién Miranda <dmiranda@nodeport.co>,
 * Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS-------------------------------------------------
//-----------------------------------------------------------------
@import "../../themeColors";
.wrapper-progress-bar{
  margin-left:20px;
  margin-right:20px;
}
.cancel-label{
  display: flex;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: rgba(0,0,0,0.65);
  color: $danger;
  font-size: 30px;
  height: 50px;
  width: calc(100% + 20px);
  z-index: 10;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  letter-spacing: 10px;
}
.pbs-wrapper-step{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 50px;
  .wrapper-icon{
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 100%;
    color: white;
    box-shadow: 1px 0px 4px #808080e6;

  }
  span{
    margin-top: 3px;
    text-align: center;
    font-size: 0.6rem;
  }
}
.accomplished{
  .wrapper-icon{
    background-color: $primary;
  }
}
