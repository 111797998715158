/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */
//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------
@import "../themeColors";

.ps-list{
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  border-radius: 0 0  0.25rem 0.25rem;
  max-height: 400px;
  overflow-y: scroll;
}
.ps-item{
  padding: 0.25rem 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

.ps-item:hover{
  background-color: rgba(42, 125, 225, 0.25);
  .ps-item-text-sku{
    color: $primary;
  }
}
.ps-item-img{
  background-color: white;
  height: 60px;
  max-width: 60px;
}
.ps-item-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5rem;
}
.ps-item-text-sku{
  color: $medium;
}
