/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//------------------------------------------------------------------
//---------THEME COLORS---------------------------------------------
//------------------------------------------------------------------
@import "../themeColors";

.wrapper-dashboard{
    display: flex;
    height: calc(100% - 62.5px);
}

.dashboard-divider{
    background: $secondary;
    background-color: $secondary;
    background-image: linear-gradient(to right,$secondary, $secondary 33.33%, $primary 33.33%, $primary 66.66%, $danger 66.66%, $danger 100%);
    height: 5px;
    width: 100%;
}


