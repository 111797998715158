/* Copyright (C) Teelblox S de RL de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * América Mendoza  <amendoza@nodeport.co>,
 * Darién Miranda <dmiranda@nodeport.co>,
 * Oscar Peña <opena@nodeport.co>,
 * June 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------
@import "../../themeColors";

.np-paginator{
  display: flex;
  width: 100%;
  justify-content: center;
  .page{
    width: 30px;
    height: 27px;
    color: black;
    font-weight: 600;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    margin:5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page:not(.ellipsis):hover{
    color: $primary;
  }
  .page.selected{
    color:white;
    background-color: $primary;
  }
  .next{
    height: 27px;
    color: black;
    font-weight: 600;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    margin:5px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .previous{
    height: 27px;
    color: black;
    font-weight: 600;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    margin:5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
