/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//Main colors
$primary: #2a7de1;
$primary-rgb: rgb(42,125,225);

$secondary: #00b2e3;
$secondary-rgb: rgb(0,178,227);

$tertiary: #a1c3e8;
$tertiary-rgb: rgb(161,195,232);

$success: #2dd36f;
$success-rgb: rgb(45,211,111);

$warning: #ffc409;
$warning-rgb: rgb(255,196,9);

$danger: #f32735;
$danger-rgb: rgb(243,39,53);

$dark: #1f1646;
$dark-rgb: rgb(31,22,70);

$medium: #d8d8da;
$medium-rgb: rgb(216,216,218);

$light: #ebebed;
$light-rgb: rgb(235,235,237);
$white: #ffffff;;
