/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------
@import "../../themeColors";


.drag-zone{
  border:2px dashed gray;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align:center;
  border-radius: 4px;
  input{
    display: none;
  }
  span{
    font-weight: bold;
    text-align: center;
    display: none;
    color:white;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  .icon{
    color: $tertiary;
  }
}
.drag-zone:hover{
  background-color: #f9f9f9;
  cursor: pointer;
}
.drag-zone.disabled{
  background-color: #e9ecef;;
  pointer-events: none;
}
.drag-zone:hover.disabled{
  background-color: #e9ecef;;
  cursor: none;
  pointer-events: none;
}
.text-image{

}
.with-image{
  border-style: solid;
  border-color: #f9f9f9;
}
.with-image:hover{
  opacity: 0.7;
  span{
    opacity: 1;
    display: block;
    border:none;
    background-color: black;
  }
}
