/* Copyright (C) Teelblox S de RL de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * América Mendoza  <amendoza@nodeport.co>,
 * Darién Miranda <dmiranda@nodeport.co>,
 * Oscar Peña <opena@nodeport.co>,
 * May 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------

@import "themeColors";

/* make the customizations */

//------------------------------------------------------------------
//---------THEME COLORS---------------------------------------------
//------------------------------------------------------------------
$theme-colors: (
        "primary":$primary,
        "secondary":$secondary,
        "success":$success,
        "danger":$danger,
        "warning":$warning,
        "info":$tertiary,
        "light":$light,
        "dark":$dark,
        "medium":$medium
);

.btn-primary{
  color: white !important;
}
.btn-danger{
  color: white !important;
}
.btn-secondary{
  color: white !important;
}
.btn-success{
  color: white !important;
}
.active{
  color: $secondary !important;
  text-decoration: underline !important;
}
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";



