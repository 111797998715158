/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2025
 */

.map-pois-wrapper{

}
.map-pois-controls{
  position: absolute;
  top: calc(100% - 120px);
  z-index: 1;
  width: 366px;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
}

