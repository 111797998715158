/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */
//-----------------------------------------------------------------
//---------IMPORTS-------------------------------------------------
//-----------------------------------------------------------------
@import "../themeColors";

.swiper-wrapper{
  width: 300px !important;
}
.card-btn{
  cursor: pointer;
}
.card-content{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reorder-card{
  height: auto;
  margin-bottom: 0.75rem;
  cursor: grab;
  border: 2px solid $medium;
  width: 100%;
  img{
    width: 150px;
    height: auto;
    border-bottom-left-radius: inherit;
  }
}
.carousel-item-preview{
  height: 200px;
  width: 300px;
  background-color: gray;
  border-radius: 5px;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.alert-wrapper{
  position: absolute;
  right: 0px;
  z-index: 100;
  top: calc( 100% - 100px);
}