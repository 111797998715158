.regions-map-buttons{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100px;

  button{
    padding:6px;
    border-radius: 0px;
    border-bottom: 1px solid white;
  }
  button:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  button:last-child{
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
