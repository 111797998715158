/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

//-----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------IMPORTS-------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------

@import "../themeColors";

.o-marker{
  //background-color: pink;
}
.o-marker{
}
.o-icon{
  cursor: pointer;
  color: $danger;
}
.btn-trigger-overlay{
  padding: 0;
  background-color: transparent;
  border: none;
}
.btn-trigger-overlay:hover{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.btn-trigger-overlay:focus{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
