/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
*/

//-----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------IMPORTS-------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
@import "themeColors";
@import "customBootstrap.scss";

//----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------HTML---------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------
html{
  margin: 0;
  padding: 0;
  height: 100%;
}
//----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------BODY---------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  margin: 0;
  padding: 0;
  height: 100%;
}
//----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------MARGINS------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------
.m-5{
  margin:5px;
}
.m-t-5{
  margin-top:5px;
}
.m-b-5{
  margin-bottom:5px;
}
.m-l-5{
  margin-left:5px;
}
.m-r-5{
  margin-right:5px;
}
.m-10{
  margin:10px;
}
.m-t-10{
  margin-top:10px;
}
.m-b-10{
  margin-bottom:10px;
}
.m-l-10{
  margin-left:10px;
}
.m-r-10{
  margin-right:10px;
}
.m-20{
  margin:20px;
}
.m-t-20{
  margin-top:20px;
}
.m-b-20{
  margin-bottom:20px;
}
.m-l-20{
  margin-left:20px;
}
.m-r-20{
  margin-right:20px;
}
//----------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------PADDINGS-----------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------
.p-5{
  padding:5px;
}
.p-t-5{
  padding-top:5px;
}
.p-b-5{
  padding-bottom:5px;
}
.p-l-5{
  padding-left:5px;
}
.p-r-5{
  padding-right:5px;
}
.p-10{
  padding:10px;
}
.p-t-10{
  padding-top:10px;
}
.p-b-10{
  padding-bottom:10px;
}
.p-l-10{
  padding-left:10px;
}
.p-r-10{
  padding-right:10px;
}
.p-20{
  padding:20px;
}
.p-t-20{
  padding-top:20px;
}
.p-b-20{
  padding-bottom:20px;
}
.p-l-20{
  padding-left:20px;
}
.p-r-20{
  padding-right:20px;
}
.validated-price{
  color: #459e00;
  font-weight: bold;
}
.nbio-hr{
  height:1px;
  background-color: #0000001f;
  width: 100%;
  margin-top:1rem;
  margin-bottom: 1rem;
}
.font-weight-normal{
  font-weight: normal !important;
}
//Tables
.react-bootstrap-table{
  overflow: auto;
}