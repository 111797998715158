/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

//-----------------------------------------------------------------
//---------IMPORTS------------------------------------------------
//-----------------------------------------------------------------

.avatar-wrapper{
  display: flex;
  align-items: center;
  .avatar{
    background-repeat:no-repeat;
    width: 50px;
    height: 50px;
    background-position: center;
    border-radius: 50%;
    background-size: cover;
  }
  .name{
    margin-left: 10px;
  }
}

